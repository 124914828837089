<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-btn rounded block text color="primary" @click="openFolderPicker" :disabled="processingData || processingCentralizator || computing || generating">
          1.
          Folder de lucru
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="6">
        <v-subheader>Fisiere date ({{ excelFiles.length }})</v-subheader>
        <v-virtual-scroll :items="excelFiles" :item-height="50" height="200">
          <template v-slot:default="{ item, index }">
            <v-list-item>
              <v-list-item-avatar class="text-caption">
                {{ index + 1 }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name" class="text-left text-caption"></v-list-item-title>
                <v-list-item-subtitle v-text="item.lastModifiedDate" class="text-left text-caption"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-col>
      <v-col cols="6">
        <v-subheader>Fisier centralizator</v-subheader>
        <v-virtual-scroll :items="centralizatorArray" :item-height="50" height="200">
          <template v-slot:default="{ item, index }">
            <v-list-item>
              <v-list-item-avatar class="text-caption">
                {{ index + 1 }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.name" class="text-left text-caption"></v-list-item-title>
                <v-list-item-subtitle v-text="item.lastModifiedDate" class="text-left text-caption"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn block text color="primary" :loading="processingData" :disabled="processingData || !excelFiles.length || computing || generating" @click="processData">
          2. Proceseaza fisiere cu date
          <template v-slot:loader>
            <v-progress-circular
              :rotate="-90"
              :width="2"
              :size="23"
              :indeterminate="!foundMonths.length"
              :active="processingData"
              :value="foundMonths.length * 100 / excelFiles.length"
              color="primary">
              <span style="font-size: 0.6rem">
                {{ (foundMonths.length * 100 / excelFiles.length).toFixed(0) }}
              </span>
            </v-progress-circular>
          </template>
        </v-btn>
        <div class="text-caption text--disabled text-center" v-show="timer.processData">
          <v-icon x-small>mdi-timer-outline</v-icon>
          {{ timer.processData }}
          <v-dialog max-width="75vw" v-model="processDataDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon x-small>
                <v-icon x-small>
                  mdi-information-outline
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h6 primary--text">
                Info
              </v-card-title>

              <v-card-text>
                <v-alert type="info" text>
                  <p class="text-caption">Gasit date pentru lunile: {{ foundMonths }}</p>
                </v-alert>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="processDataDialog = false">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
      <v-col cols="6">
        <v-btn block text color="primary" :loading="processingCentralizator" :disabled="processingCentralizator || !centralizatorFile || computing || generating" @click="processCentralizator">
          3. Proceseaza centralizator
          <template v-slot:loader>
            <v-progress-circular
              :rotate="-90"
              :width="2"
              :size="23"
              indeterminate
              color="primary">
            </v-progress-circular>
          </template>
        </v-btn>
        <div class="text-caption text--disabled text-center" v-show="timer.processCentralizator">
          <v-icon x-small>mdi-timer-outline</v-icon>
          {{ timer.processCentralizator }}
          <v-dialog max-width="75vw" v-model="processCentralizatorDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon x-small>
                <v-icon x-small>
                  mdi-information-outline
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h6 primary--text">
                Info
              </v-card-title>

              <v-card-text>
                <v-alert type="info" text>
                  <p class="text-caption">Gasit randuri in centralizator: {{ foundCentralizator }}</p>
                  <p class="text-caption">Gasit celule de completat: {{ foundStrings }}</p>
                  <p class="text-caption">Randuri cu acelasi cod abonat: </p>
                  <ul class="text-caption">
                    <li v-for="(row, index) in messagesForSameCodAbonat" :key="index">{{ row }}</li>
                  </ul>
                </v-alert>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="processCentralizatorDialog = false">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn block text color="primary" :loading="computing" :disabled="computing || foundMonths.length !== excelFiles.length || !foundCentralizator || processingData || processingCentralizator || generating" @click="compute">
          4. Calculeaza
          <template v-slot:loader>
            <v-progress-circular
              :rotate="-90"
              :width="2"
              :size="23"
              indeterminate
              color="primary">
            </v-progress-circular>
          </template>
        </v-btn>
        <div class="text-caption text--disabled text-center" v-show="timer.compute">
          <v-icon x-small>mdi-timer-outline</v-icon>
          {{ timer.compute }}
          <v-dialog max-width="75vw" v-model="computeDialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon x-small>
                <v-icon x-small>
                  mdi-information-outline
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h6 primary--text">
                Info
              </v-card-title>

              <v-card-text>
                <v-alert type="info" text>
                  <span class="text-caption">Coduri: {{ computedCodes }}</span>
                </v-alert>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="computeDialog = false">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn block text color="primary" :loading="generating" :disabled="!computingDone || generating || computing || processingData || processingCentralizator" @click="generate">
          5. Genereaza fisier xlsx
          <template v-slot:loader>
            <v-progress-circular
              :rotate="-90"
              :width="2"
              :size="23"
              indeterminate
              color="primary">
            </v-progress-circular>
          </template>
        </v-btn>
        <div class="text-caption text--disabled text-center" v-show="timer.generate">
          <v-icon x-small>mdi-timer-outline</v-icon>
          {{ timer.generate }}
        </div>
      </v-col>
    </v-row>
    <v-row v-show="processingData || processingCentralizator || computing || generating">
      <v-col cols="12" class="text-center">
        <img src="https://c.tenor.com/NrJF9_26OfkAAAAi/brita-jackhammer.gif" alt="worker gif" height="100">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import * as FileSaver from 'file-saver'
  import prettyMilliseconds from 'pretty-ms'

  /* eslint-disable no-unused-vars */
  let extractedData = {}
  let extractedCentralizator = []
  let strings = []

  export default {
    name: 'RadetIndex',

    data: () => ({
      workingFolderHandle: null,
      excelFiles: [],
      centralizatorFile: null,
      processingCentralizator: false,
      processingData: false,
      foundMonths: [],
      foundCentralizator: 0,
      computing: false,
      computingDone: false,
      generating: false,
      foundStrings: 0,
      computedCodes: 0,
      timer: {
        processData: false,
        processCentralizator: false,
        compute: false,
        generate: false
      },
      processDataDialog: false,
      processCentralizatorDialog: false,
      computeDialog: false,
      messagesForSameCodAbonat: []
    }),

    computed: {
      centralizatorArray () {
        return this.centralizatorFile ? [this.centralizatorFile] : []
      }
    },

    methods: {
      reset () {
        this.excelFiles = []
        this.centralizatorFile = null
        this.workingFolderHandle = null
        extractedData = {}
        extractedCentralizator = []
        this.foundMonths = []
        this.foundCentralizator = 0

        this.processingCentralizator = false
        this.processingData = false
        this.computing = false
        this.computingDone = false
        this.generating = false

        strings = []
        this.foundStrings = 0
        this.computedCodes = 0

        this.timer.processData = false
        this.timer.processCentralizator = false
        this.timer.compute = false
        this.timer.generate = false
        this.messagesForSameCodAbonat = []
      },
      async openFolderPicker () {
        this.reset()

        try {
          this.workingFolderHandle = await window.showDirectoryPicker()
        } catch (err) {
          console.log(err)
          return
        }

        const promises = []

        for await (const entry of this.workingFolderHandle.values()) {
          if (entry.kind === 'file') {
            if (entry.name.endsWith('xlsx') || entry.name.endsWith('xls')) {
              const file = await entry.getFile()
              if (entry.name.toLowerCase().includes('centralizator')) {
                this.centralizatorFile = file
              } else {
                this.excelFiles.push(file)
              }
            }
          } else if (entry.kind === 'directory') {
            promises.push(this.findExcels(entry))
          }
        }

        const files = await Promise.all(promises)

        this.excelFiles =  this.excelFiles.concat(files.flat())
      },
      async findExcels (directoryHandle) {
        let files = []
        const dirs = []

        for await (const entry of directoryHandle.values()) {
          if (entry.kind === 'file') {
            const file = await entry.getFile()
            if (file.name.toLowerCase().endsWith('xlsx') || file.name.toLowerCase().endsWith('xls')) {
              if (file.name.toLowerCase().includes('centralizator')) {
                this.centralizatorFile = file
              } else {
                files.push({ file })
              }
            }
          } else if (entry.kind === 'directory') {
            dirs.push(entry)
          }
        }

        for await (const dir of dirs) {
          files = [...files, ...await this.findExcels(dir)] // spreading the result of waiting for a promise to finish with an array  <--- WHAAAAT :)))
        }

        return files
      },

      async processData () {
        this.processingData = true
        this.foundMonths = []
        const start = performance.now()

        try {
          await this.parseDataFiles(this.excelFiles)
        } catch (err) {
          console.log('main error', err)
        }

        this.processingData = false
        const end = performance.now()

        this.timer.processData = prettyMilliseconds(end - start)
      },

      async parseDataFiles (files) {
        return new Promise((resolve, reject) => {
          const poolWorker = new Worker(new URL('../workers/pool.worker.js', import.meta.url))
          poolWorker.postMessage(files)
          poolWorker.onmessage = msg => {
            if (msg.data.done) {
              poolWorker.terminate()
              resolve()
            }
            if (msg.data.filename && msg.data.json) {
              const { filename, json } = msg.data
              const nameWOExt = filename.split('.')[0]
              const [lunaDenumire, an] = nameWOExt.toLowerCase().split(' ')
              const luna = convertMonthName(lunaDenumire)

              extractedData[`${an}-${luna}`] = json
              this.foundMonths.push(`${an}-${luna}`)
            }
          }
          poolWorker.onerror = err => {
            poolWorker.terminate()
            alert(err.message)
            this.$emit('open-info', 'data')
            reject(err)
          }
        })
      },

      async processCentralizator () {
        this.processingCentralizator = true
        const start = performance.now()

        const worker = new Worker(new URL('../workers/parse-centralizator-xlsx.worker.js', import.meta.url))

        worker.postMessage({ file: this.centralizatorFile })
        worker.onmessage = msg => {
          extractedCentralizator = msg.data.transformed
          this.messagesForSameCodAbonat = msg.data.messagesForSameCodAbonat
          strings = msg.data.strings
          this.foundStrings = strings.length
          this.foundCentralizator = extractedCentralizator.length
          this.processingCentralizator = false
          const end = performance.now()
          this.timer.processCentralizator = prettyMilliseconds(end - start)
          worker.terminate()
        }
        worker.onerror = err => {
          worker.terminate()
          console.log(err)
          alert(err.message)
          this.$emit('open-info', 'centralizator')
          this.processingCentralizator = false
          const end = performance.now()
          this.timer.processCentralizator = prettyMilliseconds(end - start)
        }
      },

      async compute () {
        this.computing = true
        const start = performance.now()

        let computed = await this.$worker.run(computeSums, [this.foundMonths, extractedCentralizator, extractedData])
        extractedCentralizator = computed[0]
        this.computedCodes = computed[1]

        this.computing = false
        this.computingDone = true
        const end = performance.now()
        this.timer.compute = prettyMilliseconds(end - start)
      },

      async generate () {
        this.generating = true
        const start = performance.now()

        const abTemplate = await this.readXlsxFileAsArrayBuffer(this.centralizatorFile)

        const blob = await this.generateXlsx(abTemplate)

        FileSaver.saveAs(blob, 'Rezultat.xlsx')

        this.generating = false
        const end = performance.now()
        this.timer.generate = prettyMilliseconds(end - start)
      },

      readXlsxFileAsArrayBuffer (file) {
        return new Promise((resolve, reject) => {
          try {
            const abReaderWorker = new Worker(new URL('../workers/read-array-buffer.worker.js', import.meta.url))
            abReaderWorker.postMessage({ file })

            abReaderWorker.onmessage = async msg => {
              abReaderWorker.terminate()
              resolve(msg.data)
            }
          } catch (err) {
            alert('Uh-oh, eroare la citire fisier xlsx as array buffer... Summon Dragos!')
            console.log(err)
            reject('naspa')
          }
        })
      },

      generateXlsx (abTemplate) {
        return new Promise((resolve, reject) => {
          try {
            const xlsxGeneratorWorker = new Worker(new URL('../workers/xlsx-generator.worker.js', import.meta.url))
            xlsxGeneratorWorker.postMessage({ strings, extractedCentralizator, abTemplate })

            xlsxGeneratorWorker.onmessage = async msg => {
              xlsxGeneratorWorker.terminate()
              resolve(msg.data.blob)
            }
          } catch (err) {
            alert('Uh-oh, eroare la generare fisier xlsx... Summon Dragos!')
            console.log(err)
            reject('naspa')
          }
        })
      }
    }
  }

function convertMonthName (month) {
  switch (month) {
    case 'ianuarie': return 1
    case 'februarie': return 2
    case 'martie': return 3
    case 'aprilie': return 4
    case 'mai': return 5
    case 'iunie': return 6
    case 'iulie': return 7
    case 'august': return 8
    case 'septembrie': return 9
    case 'octombrie': return 10
    case 'noiembrie': return 11
    case 'decembrie': return 12
  }
}

function computeSums (foundMonths, extractedCentralizator, extractedData) {
  let computedCodes = 0
  extractedCentralizator.forEach(row => {
    if (!row.processConsum && row.coduriAbonat.length) {
      foundMonths.forEach(month => {
        const sums = extractedData[month].reduce((acc, entry) => {
          if (row.coduriAbonat.includes(entry.codAbonat)) {
            computedCodes++
            acc.qacm += Number(entry.qacm)
            acc.qinc += Number(entry.qinc)
            acc.mcapa += Number(entry.mcapa)
          }

          return acc
        }, {
          qacm: 0,
          qinc: 0,
          mcapa: 0
        })
        row[month] = {
          qacm: sums.qacm,
          qinc: sums.qinc,
          mcapa: sums.mcapa
        }
      })
    } else if (row.processConsum && row.coduriConsum.length) {
      foundMonths.forEach(month => {
        const sums = extractedData[month].reduce((acc, entry) => {
          if (row.coduriConsum.includes(entry.codConsum)) {
            computedCodes++
            acc.qacm += Number(entry.qacm)
            acc.qinc += Number(entry.qinc)
            acc.mcapa += Number(entry.mcapa)
          }

          return acc
        }, {
          qacm: 0,
          qinc: 0,
          mcapa: 0
        })
        row[month] = {
          qacm: sums.qacm,
          qinc: sums.qinc,
          mcapa: sums.mcapa
        }
      })
    }
  })

  // console.log(extractedCentralizator)
  return [extractedCentralizator, computedCodes]
}
</script>
