<template>
  <v-app>
    <v-app-bar
      app
      color="grey lighten-5">
      <div class="d-flex align-center">
        <v-img
          alt="TUD Investment Logo"
          class="mr-5"
          contain
          src="/logo-inv-56.png"
          transition="scale-transition"
          height="56"/>
      </div>
      <v-app-bar-title class="primary--text">
        RADET
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn icon color="primary" @click="openInfo">
        <v-icon>
          mdi-information
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <RadetIndex @open-info="openInfoOnError" />

      <v-dialog v-model="infoOpen" max-width="75vw">
        <v-card>
          <v-card-title class="text-h6 primary--text">
            Info
          </v-card-title>

          <v-card-text>
            <v-alert type="info" text>
              <p class="text-left" :class="{ 'font-weight-black': emphasizeData, 'text-caption': !emphasizeData, 'text-subtitle-2': emphasizeData }">
                Principii de extragere a datelor:
                <ul>
                  <li>
                    Identificarea celulelor este case insensitive (adica nu conteaza daca textul e scris cu litere mari sau litere mici)
                  </li>
                  <li>
                    Nume fisier care contine date: "Luna an" sau "luna an" (ex.: Ianuarie 2021, august 2021).
                    Luna este transformata in format numeric (ex.: Ianuarie = 1, August = 8 etc.).
                    Extensia poate fio xlsx sau xls (case insensitive).
                  </li>
                  <li>
                    Intr-un fisier de date se determina primul rand cap de tabel cautand celula ce contine "nr. crt.".
                    Pe acelasi rand se cauta si celula ce contine "cod abonat".
                    Pe randul imediat urmator se cauta celulele care contin "qacm", "qinc" si "mcapa".
                  </li>
                  <li>
                    Tot in fisierul de date se identifica primul rand cu date cautand, pe coloana "nr. crt." determinata mai sus, prima celula care contine ceva, incepand cu primul rand dupa cel care contine "qacm", "qinc" si "mcapa".
                  </li>
                </ul>
              </p>
              <p class="text-left" :class="{ 'font-weight-black': emphasizeCentralizator, 'text-caption': !emphasizeCentralizator, 'text-subtitle-2': emphasizeCentralizator }">
                Principii prelucrare centralizator:
                <ul>
                  <li>
                    Folosind logica de mai sus se determina coloanele "id", "cod abonat" si "cod consum".
                    Celula care contine "id" va determina si ultimul rand cap de tabel.
                  </li>
                  <li>
                    Se determina primul rand de date cautand prima celula de pe coloana "id" care contine date, imediat dupa randul care contine "id".
                  </li>
                  <li>
                    Pentru fiecare rand de date se citesc toate celulele (codurile de abonat) care contin valori numerice intre coloanele "cod abonat" si "cod consum".
                    La fel se citesc toate celulele (codurile de consum) care contin valori numerice intre coloanele "cod consum" si prima coloana din capul de tabel (acelasi rand cu "cod consum") care nu este goala.
                  </li>
                  <li>
                    Din centralizator se extrag celulele ce trebuie completate cu valori dupa modelul de mai jos.
                  </li>
                </ul>
              </p>
              <p class="text-left text-caption">
                Formula pentru celula: <strong>{id_an-luna_indicator}</strong>, unde <strong>id</strong> este coloana care contine in capul de tabel cuvantul "id" (coloana C in centralizatorul de mai jos)
              </p>
              <p class="text-left text-caption">
                <ul>
                  <li>
                    qacm: <strong>   ="{" & $C10 & "_" & $AY$6 & "_" & $AY$9 & "}"  </strong>
                  </li>
                  <li>
                    qinc: <strong>  ="{" & $C10 & "_" & $AY$6 & "_" & $AZ$9 & "}"  </strong>
                  </li>
                  <li>
                    mcapa: <strong>  ="{" & $C10 & "_" & $AY$6 & "_" & $BA$9 & "}"  </strong>
                  </li>
                </ul>
              </p>
              <p class="text-left text-caption">
                Ex: <strong>{R-1_2021-1_qacm}</strong>, <strong>{R-1_2021-1_qinc}</strong>, <strong>{R-1_2021-1_mcapa}</strong>
              </p>
              <p class="text-left text-caption">
                Atentie:
                <ul>
                  <li>
                    Because reasons nu merge daca e lasata ca formula, so... se populateaza formula, apoi cut -> paste special -> text only (i know, cah...)
                  </li>
                  <li>
                    Pentru a determina id, perioada si indicator, codul din fiecare celula ({R-1_2021-1_qacm}) este spart dupa underscore ("_"). Asadar, nu folositi simbolul "_" in id, perioada sau indicator.
                  </li>
                </ul>
              </p>
              <a href="Centralizator.xlsx" download>Descarca exemplu Centralizator.xlsx </a>
            </v-alert>

            <v-alert type="warning" text>
              Aplicatia ruleaza exclusiv in browser (nu se face niciun calcul pe server).
              Asadar, performantele aplicatiei sunt strict proportionale cu performantele dispozitivului pe care ruleaza.
              Pe PC-ul meu dureaza cam 1 minut pentru o luna, 5 minute pentru 4 luni si 22 de minute pentru 12 luni.
              Atentie: durata de generare este data de numarul de celule ce trebuie completate in centralizator, indiferent daca s-au gasit date pentru ele sau nu (ex.: 4 luni/fisiere cu date, 12 luni de completat => 22 de minute).
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="closeInfo">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import RadetIndex from './components/RadetIndex'

export default {
  name: 'App',

  components: {
    RadetIndex,
  },

  data: () => ({
    infoOpen: false,
    emphasizeData: false,
    emphasizeCentralizator: false
  }),

  methods: {
    openInfo () {
      this.infoOpen = true
    },
    closeInfo () {
      this.infoOpen = false
      this.emphasizeData = false
      this.emphasizeCentralizator = false
    },
    openInfoOnError (msg) {
      this.infoOpen = true

      if (msg === 'data') {
        this.emphasizeData = true
      } else {
        this.emphasizeCentralizator = true
      }
    }
  }
};
</script>
